import React, { useRef, useState, useEffect } from "react"
import {
  FanningTriangles,
  Article,
  Heading,
  Paragraph,
  ListHeading,
  List,
  ListItem,
  ScrollTop,
} from "../components"

//icons
import { FaReact } from "@react-icons/all-files/fa/FaReact"
import { DiRedis } from "@react-icons/all-files/di/DiRedis"
import { DiDocker } from "@react-icons/all-files/di/DiDocker"
import { DiGoogleCloudPlatform } from "@react-icons/all-files/di/DiGoogleCloudPlatform"
import { DiJsBadge } from "@react-icons/all-files/di/DiJsBadge"

const TechStack: React.FC = () => {
  const [visible, setVisible] = useState(false) // is scroll to top button visible?

  const ref = useRef<HTMLHeadingElement>(null) // element that triggers visibility change

  // set visibility state based on whether viewport is past an element
  const obsCallback: IntersectionObserverCallback = (entries) => {
    // coerce to any because generic base class is used for target
    const [entry] = entries as any

    setVisible(window.scrollY > entry.target.offsetTop)
  }

  useEffect(() => {
    // create observer
    const obs = new IntersectionObserver(obsCallback)

    // store ref to ensure hook runs on the right object
    const current = ref.current

    // start observin'
    if (current) {
      obs.observe(current)
    }

    // stop observin' when component unmounts
    return () => {
      if (current) {
        obs.unobserve(current)
      }
    }
  }, [ref])

  return (
    <>
      <Article>
        <FanningTriangles />
        <Heading>Languages</Heading>
        <List>
          <ListItem>
            <ListHeading>TypeScript/JavaScript</ListHeading>- Most of my work is
            in TypeScript. I make sure to stay up to date with every feature
            release, and I try to learn everything I can about how people use
            JavaScript, and how the ECMAScript spec works.
          </ListItem>
          <ListItem>
            <ListHeading>Python</ListHeading> - For over a year, my job
            consisted primarily of writing Python scripts. During this time I
            also built and maintained a Django REST API. In other words, I'm
            fairly comfortable working with Python, but I pay less attention to
            it, and I don't use it as regularly, so I'm not as fluent.
          </ListItem>
          <ListItem>
            <ListHeading>Rust</ListHeading> - I'm a newbie when it comes to
            Rust. I've read the tutorial book and the Rustonomicon cover to
            cover, and I've built a few simple experimental things (a CLI
            application, a couple actix-web APIs, some WASM binaries), but I
            haven't done any professional work yet in Rust. I enjoy using Rust a
            lot though, so I would jump at the chance to do more lower level
            programming.
          </ListItem>
        </List>
        <DiJsBadge className="text-orange text-9xl self-center my-10" />
        <Heading ref={ref}>Front End Frameworks</Heading>
        <List>
          <ListItem>
            <ListHeading>React</ListHeading> - I've used React a lot, and I
            enjoy working with it, so it's my default choice for front end work.
            This site was made using React - Gatsby more specifically.
          </ListItem>
          <ListItem>
            <ListHeading>Angular</ListHeading> - Angular was the first tool that
            I learned when I got my start doing web development. My most complex
            front end projects were built with Angular, and I've kept up with it
            over the years. That said, it's not my first choice.
          </ListItem>
        </List>
        <FaReact className="text-orange text-9xl self-center my-10" />
        <Heading>Back End (APIs)</Heading>
        <List>
          <ListItem>
            <ListHeading>NodeJS</ListHeading> - I've been working with Node for
            as long as I have been programming. I've built several REST APIs
            using Express, and I've also built APIs using Fastify and Nest.js.
            I've taken the time to get a decent enough grasp of libuv, the V8
            engine, and how Node uses these under the hood.
          </ListItem>
          <ListItem>
            <ListHeading>rust-actix-web</ListHeading> - Although I'm relatively
            new to Rust, I've been looking seriously at the possiblity of
            replacing Node as my go-to for building API endpoints. An Actix-web
            API image is dozens of times smaller (no 120mb node_modules!),
            starts up almost instantly, and can serve many times more requests
            per second. On top of that, I haven't even found it significantly
            harder to use so far. I would love a chance to work more with
            rust-actix-web in the future.
          </ListItem>
        </List>
        <Heading>Docker</Heading>
        <Paragraph>
          Docker is another toolthat I have been using for as long as I have
          been programming. Every time I've deployed an application without
          Docker. I've regretted it. Docker is another one of the tools that
          I've taken the time to develop a pretty good understanding of.
        </Paragraph>
        <DiDocker className="text-orange text-9xl self-center my-10" />
        <Heading>Kubernetes</Heading>
        <Paragraph>
          I have been learning and using Kubernetes for a couple years now. I
          have 1 four-node cluster active on GKE right now. I would say that I'm
          decently comfortable with it. By that I meann I know how to set up a
          cluster, deploy an application to it, set up basic security and
          monitoring, and I know how to inspect what I need to inspect to
          troubleshoot an application. That said, Kubenetes is a complicated
          beast, there is a lot left for me to learn.
        </Paragraph>
        <Heading>Redis</Heading>
        <Paragraph>
          I've mostly used Redis as a session store. I also used it as a pub/sub
          messaging system in one application. I know the basics of Redis, and
          I'm comfortable configuring it on its own, and within a Kubernetes
          cluster. I wouldn't say that I'm an expert, or that I have a very in
          depth understanding of it though.
        </Paragraph>
        <DiRedis className="text-orange text-9xl self-center my-10" />
        <Heading>Databases</Heading>
        <List>
          <ListItem>
            <ListHeading>MongoDB</ListHeading> - I have both availble MongoDB
            certifications: the Database Administrator certification, and the
            Database Developer certification. I've also built several
            applications using MongoDB. I have a very good understanding of how
            and when to use MongoDB
          </ListItem>
          <ListItem>
            <ListHeading>PostgreSQL</ListHeading> - I've used Postgres in a few
            learning projects, and I have a decent grasp of at least basic
            usage. However, my Postgres skills don't extend very far beyond
            that. Changing this is pretty close to the top of my priority list
            at the moment.
          </ListItem>
        </List>
        <Heading>Networking/Servers</Heading>
        <List>
          <ListItem>
            <ListHeading>Nginx</ListHeading> - I've used nginx to serve static
            files, and as a reverse-proxy. It has a lot of modules that I
            haven't used, but I'm very comfortable with the core concepts.
          </ListItem>
          <ListItem>
            <ListHeading>Istio</ListHeading> - Istio is my go-to for networking
            whenever I'm using Kubernetes. When it comes to managing requests to
            and from a cluster, Istio can do literally anything, so I've never
            felt compelled to use anything else.
          </ListItem>
        </List>
        <Heading>Cloud Providers - Google Cloud Platform</Heading>
        <Paragraph>
          Although I have used AWS a tiny bit, I prefer using GCP over it and
          other alternatives. Using GCP means workspace resources (e.g. drive,
          email, sheets), cloud storage, GKE, APIs (e.g. Google Maps), domains,
          analytics, container registry etc. can all be managed from one place.
          Also, GCP tends to be relatively cost efficient for Kubernetes.
        </Paragraph>
        <DiGoogleCloudPlatform className="text-orange text-9xl self-center my-10" />
        <Heading>CI/CD - Github Actions</Heading>
        <Paragraph>
          I've been using Github Actions for a while now and I'm pretty happy
          with it. I've used it to build and deploy images to Kubernetes
          clusters. I've used it to run unit and integration tests, and I've
          used it to lint code. I'm generally happy with it. I'm a fan of
          anything that helps me reduce the number of companies I need to
          interact with. Also, being able to make re-usable CI/CD packages is
          extremely useful.
        </Paragraph>
      </Article>
      {visible && <ScrollTop />}
    </>
  )
}

export default TechStack
